import React from 'react';

import LogoSVG from './thyme-logo.svg';

function Logo() {
  return (
    <LogoSVG />
  );
}

export default Logo;